import React from 'react'
import { Fade } from 'react-reveal'

const ProductPageSection1 = ({ productSection1 }) => {
  if (!productSection1) {
    return ''
  }

  return (
    <>
      <div className='content-container padding-0 display-none-on-small' style={{ height: '100vh', minHeight: '560px', backgroundColor: productSection1.section_colors.document ? productSection1.section_colors.document.data.background_color : null, backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: productSection1.background_image ? `url(${productSection1.background_image.url})` : null }}>
        <div className='row m-0' style={{ height: '100%' }}>
          <div
            className='col-6 content-container-horizontal justify-content-between align-items-end'
            style={{ height: '100%', paddingLeft: '10vw' }}>
            {productSection1.paragraph1_title
              ? <Fade left><div style={{ display: 'contents' }}><span className='vertical-text font-main color-primary-dark' style={{ marginRight: '24px', color: productSection1.section_colors.document ? productSection1.section_colors.document.data.primary_color : null }}>
                {productSection1.paragraph1_title}
              </span></div></Fade>
              : ''}
            {productSection1.paragraph1_content
              ? <Fade bottom><div className='content-container padding-0'>
                {productSection1.paragraph1_content.split('\n').flatMap((text, i) => {
                  return (
                    <p key={i} className='font-main paragraph color-primary-dark' style={{ color: productSection1.section_colors.document ? productSection1.section_colors.document.data.secondary_color : null }}>
                      {text}
                    </p>
                  )
                })}
              </div></Fade>
              : ''}
          </div>
          <div className='col-6 content-container padding-0' style={{ height: '100%' }}>
            <div
              className='content-container-horizontal justify-content-between align-items-end'
              style={{ width: '100%', height: '100%', paddingLeft: '5vw', paddingRight: '10vw' }}>
              {productSection1.paragraph2_title
                ? <Fade left><div style={{ display: 'contents' }}><span className='vertical-text font-main color-primary-dark' style={{ marginRight: '24px', color: productSection1.section_colors.document ? productSection1.section_colors.document.data.secondary_color : null }}>
                  {productSection1.paragraph2_title}
                </span></div></Fade>
                : ''}
              {productSection1.paragraph2_content
                ? <Fade bottom><div className='content-container padding-0'>
                  {productSection1.paragraph2_content.split('\n').flatMap((text, i) => {
                    return (
                      <p key={i} className='font-main paragraph color-primary-dark' style={{ color: productSection1.section_colors.document ? productSection1.section_colors.document.data.secondary_color : null }}>
                        {text}
                      </p>
                    )
                  })}
                </div></Fade>
                : ''}
            </div>
            <div className='content-container padding-0' style={{ width: '100%', height: '100%', position: 'relative' }}>
              {productSection1.productsection1_image
                ? <img
                  src={productSection1.productsection1_image.url} alt=''
                  style={{ position: 'absolute', right: 0, maxHeight: '100%', maxWidth: '100%' }} />
                : ''}
            </div>
          </div>
        </div>
        <div
          className='row m-0 content-container'
          style={{ width: '100%', justifyContent: 'space-between', minHeight: '140px' }}>
          <span className='font-main color-primary-dark' style={{ fontSize: '21px', marginBottom: '24px', color: productSection1.section_colors.document ? productSection1.section_colors.document.data.secondary_color : null }}>scroll for more</span>
          <svg
            className='bounce-simple animated infinite'
            width='12' height='33' viewBox='0 0 12 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M5.25364 30.4393V0H6.74636V30.4393L10.222 26.9467C10.5135 26.6538 10.9861 26.6538 11.2776 26.9467C11.569 27.2396 11.569 27.7145 11.2776 28.0074L6.52775 32.7803C6.23628 33.0732 5.76372 33.0732 5.47224 32.7803L0.722449 28.0074C0.430977 27.7145 0.430977 27.2396 0.722449 26.9467C1.01392 26.6538 1.48649 26.6538 1.77796 26.9467L5.25364 30.4393Z' fill={productSection1.section_colors.document ? productSection1.section_colors.document.data.secondary_color : '#030F4B'} />
          </svg>
        </div>
      </div>
      <div className='content-container padding-0 display-none-on-medium' style={{ backgroundColor: productSection1.section_colors.document ? productSection1.section_colors.document.data.background_color : null, backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: productSection1.backgroundImage ? `url(${productSection1.backgroundImage})` : null }}>
        <div className='row m-0'>
          <div
            className='col-12 content-container-horizontal justify-content-between align-items-end'
            style={{ width: '100vw' }}>
            {productSection1.paragraph1_title
              ? <Fade left><div style={{ display: 'contents' }}><span className='vertical-text font-main color-primary-dark' style={{ marginRight: '24px', color: productSection1.section_colors.document ? productSection1.section_colors.document.data.secondary_color : null }}>
                {productSection1.paragraph1_title}
              </span></div></Fade>
              : ''}
            {productSection1.paragraph1_content
              ? <Fade bottom><div className='content-container padding-0'>
                {productSection1.paragraph1_content.split('\n').flatMap((text, i) => {
                  return (
                    <p key={i} className='font-main quint-body color-primary-dark' style={{ color: productSection1.section_colors.document ? productSection1.section_colors.document.data.secondary_color : null, fontWeight: 400 }}>
                      {text}
                    </p>
                  )
                })}
              </div></Fade>
              : ''}
          </div>
          <hr className='divider' style={{ color: productSection1.section_colors.document ? productSection1.section_colors.document.data.primary_color : null }} />
          <div className='col-12 content-container padding-0' style={{ width: '100vw' }}>
            <div
              className='content-container-horizontal justify-content-between align-items-end'>
              {productSection1.paragraph2_title
                ? <Fade left><div style={{ display: 'contents' }}><span className='vertical-text font-main color-primary-dark' style={{ marginRight: '24px', color: productSection1.section_colors.document ? productSection1.section_colors.document.data.secondary_color : null }}>
                  {productSection1.paragraph2_title}
                </span></div></Fade>
                : ''}
              {productSection1.paragraph2_content
                ? <Fade bottom><div className='content-container padding-0'>
                  {productSection1.paragraph2_content.split('\n').flatMap((text, i) => {
                    return (
                      <p key={i} className='font-main paragraph color-primary-dark' style={{ color: productSection1.section_colors.document ? productSection1.section_colors.document.data.secondary_color : null }}>
                        {text}
                      </p>
                    )
                  })}
                </div></Fade>
                : ''}
            </div>
          </div>
        </div>
        <div
          className='row m-0 content-container'
          style={{ width: '100%', justifyContent: 'space-between', minHeight: '100px' }}>
          <span className='font-main color-primary-dark' style={{ fontSize: '21px', marginBottom: '24px', color: productSection1.section_colors.document ? productSection1.section_colors.document.data.secondary_color : null }}>scroll for more</span>
          <svg
            className='bounce-simple animated infinite'
            width='12' height='33' viewBox='0 0 12 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M5.25364 30.4393V0H6.74636V30.4393L10.222 26.9467C10.5135 26.6538 10.9861 26.6538 11.2776 26.9467C11.569 27.2396 11.569 27.7145 11.2776 28.0074L6.52775 32.7803C6.23628 33.0732 5.76372 33.0732 5.47224 32.7803L0.722449 28.0074C0.430977 27.7145 0.430977 27.2396 0.722449 26.9467C1.01392 26.6538 1.48649 26.6538 1.77796 26.9467L5.25364 30.4393Z' fill={productSection1.section_colors.document ? productSection1.section_colors.document.data.secondary_color : '#030F4B'} />
          </svg>
        </div>
      </div>
    </>
  )
}

export default ProductPageSection1
