import React from 'react'
import ProductPageTemplate from '../components/ProductPageTemplate'
import { graphql } from 'gatsby'

function addSectionColors(data) {
  const dataArray = []
  if (data.body) {
    data.body.forEach(sec => {
      const sectionData = {
        ...sec,
      }
      sectionData.primary.section_colors = sectionData.primary.section_colors ? sectionData.primary.section_colors : data.page_colors
      dataArray.push(sectionData)
    })
  }

  return dataArray
}

function seoData(uid, createdAt, data) {
  const dataObject = {
    slug: '/' + uid,
    title: data.meta_title,
    description: data.meta_description,
    cover: data.image ? data.image.url : '',
    date: createdAt
  }

  return dataObject
}

function createSection0(data) {
  const dataObject = {
    title: data.title,
    subtitle: data.subtitle,
    cover_image: data.cover_image,
    image: data.image,
    section_colors: data.page_colors
  }

  return dataObject
}

function createTestimonialSection(data) {
  let dataObject = null
  if (data.testimonial) {
    dataObject = {
      ...data.testimonial,
      section_colors: data.page_colors
    }
  }

  return dataObject
}

const ProductPage = ({ data }) => {
  if (!data || !data.prismicProduct) {
    return ''
  }
  const { data: productData } = data.prismicProduct

  const productSection0 = createSection0(productData)
  const sectionData = addSectionColors(productData)
  const testimonialSection = createTestimonialSection(productData)

  const productSeo = seoData(data.prismicProduct.uid, data.prismicProduct.first_publication_date, productData)

  return (
    <ProductPageTemplate
      productSeo={productSeo}
      meta_title={productData.meta_title}
      meta_description={productData.meta_description}
      topSection={productSection0}
      productSections={sectionData}
      testimonial={testimonialSection}
    />
  )
}

export default ProductPage

export const productQuery = graphql`
    query ProductPage($id: String!) {
        prismicProduct(id: { eq: $id }) {
            uid
            first_publication_date
            data {
                meta_title
                meta_description
                category
                title
                subtitle
                cover_image {
                    url
                    alt
                    fluid {
                      src
                      base64
                      aspectRatio
                      sizes
                      srcSetWebp
                      srcWebp
                      srcSet
                    }
                }
                image {
                    url
                }
                 testimonial {
                    document {
                        ... on PrismicTestimonial {
                            id
                            data {
                                author
                                job_title
                                quote
                                company_logo {
                                  url
                                }
                            }
                        }
                    }
                 }
                page_colors {
                    document {
                        ... on PrismicPageColors {
                            id
                            data {
                                background_color
                                primary_color
                                secondary_color
                                tertiary_color
                            }
                        }
                    }
                }
                body {
                    __typename
                    ... on PrismicProductBodyProductSection1 {
                        id
                        primary {
                            paragraph1_title
                            paragraph1_content
                            paragraph2_title
                            paragraph2_content
                            productsection1_image {
                                url
                            }
                            section_colors {
                                document {
                                    ... on PrismicPageColors {
                                        id
                                        data {
                                            background_color
                                            primary_color
                                            secondary_color
                                            tertiary_color
                                        }
                                    }
                                }
                            }
                            background_image {
                                url
                            }
                        }
                    }
                    ... on PrismicProductBodyProductSection2 {
                        id
                        primary {
                            productsection2_title
                            productsection2_subtitle
                            productsection2_button_text
                            button_url {
                                url
                            }
                            media_position
                            productsection2_media {
                                document {
                                    ... on PrismicMedia {
                                        id
                                        data {
                                            type
                                            speed
                                            file {
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                            section_colors {
                                document {
                                    ... on PrismicPageColors {
                                        id
                                        data {
                                            background_color
                                            primary_color
                                            secondary_color
                                            tertiary_color
                                        }
                                    }
                                }    
                            }
                            background_image {
                                url
                            }
                        }
                    }
                    ... on PrismicProductBodyProductSection3 {
                        id
                        primary {
                            productsection3_title
                            productsection3_subtitle
                            media {
                                document {
                                    ... on PrismicMedia {
                                        id
                                        data {
                                            type
                                            speed
                                            file {
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                            medialeft {
                                document {
                                    ... on PrismicMedia {
                                        id
                                        data {
                                            type
                                            speed
                                            file {
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                            mediaright {
                                document {
                                    ... on PrismicMedia {
                                        id
                                        data {
                                            type
                                            speed
                                            file {
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                            section_colors {
                                document {
                                    ... on PrismicPageColors {
                                        id
                                        data {
                                            background_color
                                            primary_color
                                            secondary_color
                                            tertiary_color
                                        }
                                    }
                                }    
                            }
                            background_image {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`
