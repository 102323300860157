import React from 'react'
import { LoadableQuintTestimonial } from '../LoadableComponents'

const ProductPageTestimonial = ({ productTestimonial }) => {
  if (!productTestimonial.document) {
    return ''
  }

  return (
    <>
      <div
        className='content-container display-none-on-medium'
        style={{ height: '100vh', minHeight: '660px', position: 'relative', paddingTop: '15vh', paddingBottom: '15vh', backgroundColor: productTestimonial.section_colors.document ? productTestimonial.section_colors.document.data.background_color : null }}>
        <LoadableQuintTestimonial
          icon={productTestimonial.document && productTestimonial.document.data.company_logo ? productTestimonial.document.data.company_logo.url : null}
          style={{ flex: 1 }}
          content={
            <p style={{ fontWeight: 300, color: productTestimonial.section_colors.document ? productTestimonial.section_colors.document.data.primary_color : null }}>
              {productTestimonial.document && productTestimonial.document.data.quote}
            </p>
          }
          name={
            <span style={{ color: productTestimonial.section_colors.document ? productTestimonial.section_colors.document.data.secondary_color : null }}>
              {productTestimonial.document && productTestimonial.document.data.author}
            </span>
          }
          jobDescription={
            <span style={{ color: productTestimonial.section_colors.document ? productTestimonial.section_colors.document.data.tertiary_color : null }}>
              {productTestimonial.document && productTestimonial.document.data.job_title}
            </span>
          }
        />
      </div>
      <div
        className='content-container display-none-on-small'
        style={{ height: '100vh', minHeight: '660px', position: 'relative', paddingTop: '12%', paddingBottom: '12%', backgroundColor: productTestimonial.section_colors.document ? productTestimonial.section_colors.document.data.background_color : null }}>
        <LoadableQuintTestimonial
          icon={productTestimonial.document && productTestimonial.document.data.company_logo ? productTestimonial.document.data.company_logo.url : null}
          style={{ flex: 1 }}
          content={
            <p style={{ fontWeight: 300, color: productTestimonial.section_colors.document ? productTestimonial.section_colors.document.data.primary_color : null }}>
              {productTestimonial.document && productTestimonial.document.data.quote}
            </p>
          }
          name={
            <span style={{ color: productTestimonial.section_colors.document ? productTestimonial.section_colors.document.data.secondary_color : null }}>
              {productTestimonial.document && productTestimonial.document.data.author}
            </span>
          }
          jobDescription={
            <span style={{ color: productTestimonial.section_colors.document ? productTestimonial.section_colors.document.data.tertiary_color : null }}>
              {productTestimonial.document && productTestimonial.document.data.job_title}
            </span>
          }
        />
      </div>
    </>
  )
}

export default ProductPageTestimonial
