import React from 'react'
import { Fade } from 'react-reveal'
import useBreakpoint from '../../customHooks/useBreakpoint'
import { LoadableQuintMediaContainer } from '../LoadableComponents'

const ProductPageSection6 = ({ productSection6 }) => {
  const isMobile = useBreakpoint('sm', false)

  if (!productSection6) {
    return ''
  }

  return (
    <div className='content-container' style={{ height: isMobile ? 'unset' : '100vh', minHeight: '560px', position: 'relative', backgroundColor: productSection6.section_colors.document ? productSection6.section_colors.document.data.background_color : null, backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: productSection6.background_image ? `url(${productSection6.background_image.url})` : null }}>
      {productSection6.productsection3_title
        ? <Fade bottom><span className='quint-heading font-main color-primary-dark' style={{ textAlign: 'center', color: productSection6.section_colors.document ? productSection6.section_colors.document.data.secondary_color : null }}>{productSection6.productsection3_title}</span></Fade>
        : ''}
      {productSection6.productsection3_subtitle
        ? <Fade bottom><span className='paragraph font-main color-primary' style={{ textAlign: 'center', color: productSection6.section_colors.document ? productSection6.section_colors.document.data.primary_color : null }}>{productSection6.productsection3_subtitle}</span></Fade>
        : ''}
      {productSection6.media
        ? <LoadableQuintMediaContainer
          type={productSection6.media.document && productSection6.media.document.data.type}
          path={productSection6.media.document && productSection6.media.document.data.file.url}
          mediaSpeed={productSection6.media.document && productSection6.media.document.data.speed}
          style={{ height: '60%', maxWidth: '70%', marginTop: '5%' }} />
        : ''}
      {productSection6.medialeft
        ? <LoadableQuintMediaContainer
          className='display-none-on-small'
          type={productSection6.medialeft.document && productSection6.medialeft.document.data.type}
          path={productSection6.medialeft.document && productSection6.medialeft.document.data.file.url}
          mediaSpeed={productSection6.medialeft.document && productSection6.medialeft.document.data.speed}
          style={{ height: '30%', width: '30%', position: 'absolute', bottom: '12%', left: '-12%' }} />
        : ''}
      {productSection6.mediaright
        ? <LoadableQuintMediaContainer
          className='display-none-on-small'
          type={productSection6.mediaright.document && productSection6.mediaright.document.data.type}
          path={productSection6.mediaright.document && productSection6.mediaright.document.data.file.url}
          mediaSpeed={productSection6.medialeft.document && productSection6.mediaright.document.data.speed}
          style={{ height: '30%', width: '30%', position: 'absolute', top: '12%', right: '-12%' }} />
        : ''}
    </div>
  )
}

export default ProductPageSection6
