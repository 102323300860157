import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import config from '../../../config'
import Seo from './index'

function ProductSeo({ description, lang, meta, title, slug, cover, date }) {
  const breadcrumbSchemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': config.siteUrl + '/portfolio',
          name: 'Portfolio',
          image: config.siteUrl + '/icons/icon-512x512.png',
        },
      },
      {
        '@type': 'ListItem',
        position: 2,
        item: {
          '@id': config.siteUrl + '/portfolio' + slug,
          name: title,
          image: cover,
        },
      },
    ],
  }

  const productSchemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'Product',
    url: config.siteUrl + '/portfolio' + slug,
    name: title,
    alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    headline: title,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': config.siteUrl + '/portfolio' + slug,
    },
    author: {
      '@type': 'Person',
      name: config.userName,
    },
    image: {
      '@type': 'ImageObject',
      url: cover,
    },
    datePublished: date,
    publisher: {
      '@type': 'Organization',
      name: config.siteTitle,
      logo: {
        '@type': 'ImageObject',
        url: config.siteUrl + '/icons/icon-512x512.png',
      },
    },
    description: description,
  }

  return (
    <>
      <Seo title={title} description={description} lang={lang} meta={meta} />
      <Helmet>
        {/* Schema.org tags */}
        <script type='application/ld+json'>
          {JSON.stringify(breadcrumbSchemaOrgJSONLD)}
        </script>
        <script type='application/ld+json'>
          {JSON.stringify(productSchemaOrgJSONLD)}
        </script>
      </Helmet>
    </>
  )
}

ProductSeo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

ProductSeo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  cover: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
}

export default ProductSeo
