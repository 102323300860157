import React from 'react'
import { Fade } from 'react-reveal'
import useBreakpoint from '../../customHooks/useBreakpoint'
import { LoadableQuintContentCard } from '../LoadableComponents'

const ProductPageSection2 = ({ productSection2 }) => {
  const isMobile = useBreakpoint('sm', false)

  if (!productSection2) {
    return ''
  }

  return (
    <>
      <div
        className='content-container'
        style={{
          height: isMobile ? 'unset' : '100vh',
          minHeight: '560px',
          paddingLeft: '8vw',
          paddingRight: '8vw',
          backgroundColor: productSection2.section_colors.document ? productSection2.section_colors.document.data.background_color : null,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: productSection2.background_image ? `url(${productSection2.background_image.url})` : null
        }}>
        <Fade bottom>
          <div style={{ height: '100%' }}>
            <LoadableQuintContentCard
              style={{ cursor: 'default' }}
              height='100%'
              mediaHeight='100%'
              mediaObjectFit='contain'
              media={productSection2.productsection2_media.document.data ? productSection2.productsection2_media.document.data.file.url : null}
              title={productSection2.productsection2_title}
              subtitle={productSection2.productsection2_subtitle}
              buttonText={productSection2.productsection2_button_text}
              mediaPosition={productSection2.media_position ? 'left' : 'right'}
              primaryColor={productSection2.section_colors.document ? productSection2.section_colors.document.data.secondary_color : '#030F4B'}
              secondaryColor={productSection2.section_colors.document ? productSection2.section_colors.document.data.tertiary_color : '#00000059'}
              underlineColor={productSection2.section_colors.document ? productSection2.section_colors.document.data.secondary_color : '#25317B'}
            >
              {productSection2.button_url.url && <a href={productSection2.button_url.url} />}
            </LoadableQuintContentCard>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default ProductPageSection2
