import React from 'react'
import { Fade } from 'react-reveal'
import useBreakpoint from '../../customHooks/useBreakpoint'
import { navigate } from 'gatsby-link'
import Img from 'gatsby-image'

const ProductPageSection0 = ({ productSection0 }) => {
  const isMobile = useBreakpoint('sm', false)

  if (!productSection0) {
    return ''
  }

  return (
    <div
      className='content-container padding-0' style={{
        marginTop: 85,
        height: `calc(100vh - 85px)`,
        backgroundColor: productSection0.section_colors.document ? productSection0.section_colors.document.data.background_color : null
      }}>
      <div style={{ width: '100%', height: '10%', display: 'flex' }}>
        <div
          onClick={() => navigate('/portfolio')}
          className='font-main paragraph color-primary-dark'
          style={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'inherit',
            color: 'inherit',
            width: 'fit-content',
            cursor: 'pointer'
          }}>
          <svg
            width='32' height='14' viewBox='0 0 32 14' fill='none'
            style={{ width: '2rem', marginRight: '1.5rem', marginLeft: '1.5rem' }}>
            <path
              d='M6.36413 0.168048C6.58773 -0.0635955 6.94202 -0.0546535 7.15546 0.188021C7.3689 0.430695 7.36066 0.815206 7.13706 1.04685L2.1932 6.16849H31.4403C31.7494 6.16849 32 6.44045 32 6.77593C32 7.11142 31.7494 7.38338 31.4403 7.38338H2.1263L7.15757 12.9679C7.37368 13.2077 7.3697 13.5923 7.14868 13.8269C6.92766 14.0614 6.57329 14.0571 6.35718 13.8172L0 6.76103L6.36413 0.168048Z'
              fill={productSection0.section_colors.document ? productSection0.section_colors.document.data.primary_color : '#030F4B'} />
          </svg>
          <span
            className='color-primary-dark'
            style={{ color: productSection0.section_colors.document ? productSection0.section_colors.document.data.primary_color : null }}>Portfolio</span>
        </div>
      </div>
      <div style={{ width: '100vw', padding: '0 8vw', height: isMobile ? '60%' : '30%', display: 'flex', alignItems: 'center' }}>
        <div
          className='row d-flex align-items-center justify-content-md-between justify-content-center'
          style={{ height: 'fit-content', flex: 1 }}>
          {productSection0.title
            ? <Fade bottom><span
              className='col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start quint-heading-big font-main color-primary-dark'
              style={{
                fontWeight: 'normal',
                color: productSection0.section_colors.document ? productSection0.section_colors.document.data.secondary_color : null
              }}>
              {productSection0.title}
            </span></Fade>
            : ''}
          {productSection0.subtitle
            ? <Fade bottom><span
              className='col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end quint-subtitle font-main color-black-transparent'
              style={{ color: productSection0.section_colors.document ? productSection0.section_colors.document.data.tertiary_color : null }}>
              {productSection0.subtitle}
            </span></Fade>
            : ''}
        </div>
      </div>
      <div className='display-none-on-small' style={{ width: '100%' }}>
        <Img
          fluid={productSection0.cover_image.fluid} alt={productSection0.cover_image.alt}
          style={{ width: '100%' }} />
      </div>
      <img
        className='display-none-on-medium' src={productSection0.cover_image.url} alt={productSection0.cover_image.alt}
        style={{ width: '100%', height: '30%', objectFit: 'contain', objectPosition: 'center bottom' }} />
    </div>
  )
}

export default ProductPageSection0
