import React from 'react'
import ProductPageSection0 from '../ProductPageSection0'
import ProductPageSection1 from '../ProductPageSection1'
import ProductPageSection2 from '../ProductPageSection2'
import ProductPageSection6 from '../ProductPageSection6'
import ProductPageTestimonial from '../ProductPageTestimonial'
import ProductSeo from '../SEO/ProductSeo'

const ProductPageTemplate = ({
  productSeo,
  topSection,
  productSections,
  testimonial,
}) => {
  return (
    <div>
      <ProductSeo {...productSeo} />
      <ProductPageSection0 productSection0={topSection} />
      {productSections && productSections.map(productSection => {
        switch (productSection.__typename) {
          case 'PrismicProductBodyProductSection1':
            return <ProductPageSection1 key={productSection.id} productSection1={productSection.primary} />
          case 'PrismicProductBodyProductSection2':
            return <ProductPageSection2 key={productSection.id} productSection2={productSection.primary} />
          case 'PrismicProductBodyProductSection3':
            return <ProductPageSection6 key={productSection.id} productSection6={productSection.primary} />
          default:
            return ''
        }
      })}
      <ProductPageTestimonial productTestimonial={testimonial} />
    </div>
  )
}

export default ProductPageTemplate
